@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: "Onest", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* /hide input type number spinner/ */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

::-moz-selection {
    color: white;
    background: #ff7c30;
}

::selection {
    color: white;
    background: #ff7c30;
}

::-webkit-scrollbar {
    width: 5px;
    border-radius: 15px;
}

/* 
/ Track / */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 15px;
}

/* / Handle / */
::-webkit-scrollbar-thumb {
    background: #d5d5d5;
    border-radius: 15px;
}

/* / Handle on hover / */
::-webkit-scrollbar-thumb:hover {
    background: #98a0a9;
}

h1 {
    font-size: 32px;
    font-weight: bold;
}

h2 {
    font-size: 24px;
    font-weight: bold;
}

h3 {
    font-size: 20px;
    font-weight: bold;
}

h4 {
    font-size: 18px;
    font-weight: bold;
}

h5 {
    font-size: 16px;
    font-weight: bold;
}

p {
    font-size: 14px;
    color: #555555;
}

label {
    font-size: 14px;
    color: #555555;
}

input[type="checkbox"]:checked {
    border-color: transparent;
}

/* Radio Button */
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: "";
    width: 10px;
    height: 10px;
    background: #ff7c30;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

/* Radio Button End */

/* OTP Validation */
.otp-root button {
    color: #ff7c30;
    margin-right: 3em;
}

.root-otp button {
    color: #ff7c30;
    margin-right: 4em;
}

.pl-17 {
    padding-left: 4.5rem;
}

.resend-text-position {
    height: 14px;
    padding-top: 1px;
}

.resend-text {
    color: #555555;
    margin-left: 2.6em;
    margin-right: 0.2em;
}

/* OTP Validation End*/

.mobileTabsScroll::-webkit-scrollbar,
.mobileTabsScroll::-webkit-scrollbar-thumb {
    background: transparent;
    height: 0;
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* Accordion  */
.accordionCheck {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.2);
}

.tab {
    width: 100%;
    color: #101e26;
    overflow: hidden;
}

.tab-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    background: White;
    font-weight: bold;
    cursor: pointer;
    /* Icon */
}

.tab-label:hover {
    background: white;
}

.tab-label::after {
    content: url(assets/accordion2.png);
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.5s;
    margin-right: 25px;
    margin-bottom: 25px;
}

.tab-content {
    max-height: 0;
    padding: 0 1em;
    color: #2c3e50;
    transition: all 0.35s;
    background: white;
}

.tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: white;
    cursor: pointer;
}

.tab-close:hover {
    background: white;
}

input:checked + .tab-label {
    background: white;
}

input:checked + .tab-label::after {
    content: url(assets/accordion.png);
}

input:checked ~ .tab-content {
    max-height: 100vh;
    padding: 1em;
}

/* Accordion End */

.customScroll::-webkit-scrollbar {
    width: 5px;
    border-radius: 15px;
    height: 4px;
}

.customScroll::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 15px;
}

.customScroll::-webkit-scrollbar-thumb {
    background: #d5d5d5;
    border-radius: 15px;
}

.customScroll::-webkit-scrollbar-thumb:hover {
    background: #98a0a9;
}

#paybutton {
    width: 152px;
    height: 40px;
    border-radius: 4px;
    background-color: #ff9e30;
    margin-top: 20px;
}



.cursor {
    display: inline-block;
    width: 1ch;
    animation: flicker 0.5s infinite;
    margin-bottom: 4px;
  }

  @keyframes flicker {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }